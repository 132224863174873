<template>
  <div>
    <v-app-bar app clipped-left dark color="#F0C316">
      <!-- <v-app-bar-nav-icon @click.stop="drawerToggle" /> -->

      <v-toolbar-title
        class="mr-12 align-center pointer"
        @click="$router.push('/')"
      >
        <span
          class="pl-2 mr-2 font-weight-light"
          v-if="!$vuetify.breakpoint.xsOnly"
          style="color: white; font-size: 25px;"
          >smpl <span class="font-weight-black black--text">sale</span>
        </span>
      </v-toolbar-title>
      <v-spacer> </v-spacer>
      <!-- <v-btn text @click="$vuetify.goTo('#price')">
        Price
      </v-btn> -->

      <v-btn text @click="$router.push('/signup')">
        Sign Up
      </v-btn>

      <v-btn text @click="$router.push('/login')">
        Login
      </v-btn>
    </v-app-bar>
    <v-content>
      <v-container grid-list-md>
        <v-row>
          <v-col cols="12" class="mb-10 mt-5"
            ><div class="text-center display-3">
              Terms and Conditions
            </div></v-col
          >
          <v-col cols="12">
            <div class="title mb-2">
              SOFTWARE LICENSE AND SERVICES AGREEMENT
            </div>
            <p>
              Thank you for choosing Matt Consulting Services Inc Point of Sale
              Software and Services. This Software License and Services
              Agreement (this “Agreement” together with the Sign Up Page on our
              website (smplsale.com) (the “Sign Up Page”), if any, is (this
              “Agreement”)) gives you certain rights and responsibilities
              depending on the software license (the “Software”) and services
              (the “Services”) you purchase, as more fully described below. If
              you do not accept this Agreement, you will not be able to use the
              Software or Services.
            </p>

            <p>
              In addition to the Matt Consulting Services Inc Point of Sale Software
              and Services, the term “Software” includes any other programs,
              tools, internet-based services, components and any “updates” (for
              example, Software maintenance, service information, help content,
              bug fixes, or maintenance releases etc.) or “upgrades” of the
              Software that Matt Consulting Services Inc provides or makes available
              to you after the date you obtained your initial copy of the
              Software, and the term “Services” includes any other services
              provided to you after the date of your initial activation of the
              Services. You are entitled to download updates to the Software
              that Matt Consulting Services Inc generally makes available to other
              users of the Software.
            </p>
          </v-col>

          <v-col cols="12">
            <div class="title mb-2">
              1. LICENSE GRANT AND RESTRICTIONS.
            </div>
            <p>
              Matt Consulting Services Inc., d/b/a smplsale, a New York
              corporation (“Matt Consulting Services Inc”, “us”, “we” and “our”)
              grants you (“you”, “your” and “Customer” means you the customer on
              whose behalf the Software is licensed and Services are sold) the
              following rights provided that you comply with all of the terms
              and conditions of this Agreement.
            </p>

            <p>
              License: You May: install the Software on unlimited computers per
              store location. You are not licensed or permitted under this
              Agreement and you agree that you shall not, nor permit others to
              do any of the following:
            </p>
            <p>
              (a) modify, adapt, translate, rent or sublicense (including
              offering the Software to third parties on an applications service
              provider or time-sharing basis);
            </p>
            <p>
              (b) assign, loan, resell, rebrand, transfer or distribute the
              Software, or related materials or create derivative works based
              upon the Software or any part thereof;
            </p>
            <p>(c) decompile, reverse engineer, or disassemble the Software;</p>
            <p>
              (d) copy the Software in whole or part, alter, adjust, repair or
              circumvent any aspect of the Software, or use trade secret
              information contained in the Software, to develop software to
              interface with the Software; and
            </p>
            <p>
              (e) remove, alter or obscure any confidentiality or proprietary
              rights notices (including copyright notices) of Matt Consulting Services Inc or its licensors on or within the Software or any copies
              of the Software. Additionally, Customer shall not use, nor shall
              it permit others to use the Software:
            </p>
            <p>
              (a) for any unlawful, invasive, infringing, defamatory, fraudulent
              or obscene purpose;
            </p>
            <p>
              (b) to send any virus, worm, trojan horse or harmful code or
              attachment;
            </p>
            <p>
              (c) to alter, steal, corrupt, disable, destroy, trespass or
              violate any security or encryption of any computer file, database
              or network;
            </p>
            <p>
              (d) so as to interfere with the use of the Matt Consulting Services Inc
              or connectivity partner network by other customers or authorized
              users; or
            </p>
            <p>
              (e) in violation of the acceptable use policies of Matt Consulting Services Inc service providers, including its backbone providers.
            </p>
            <p>
              In the event you choose to expand the number of locations that
              will use Software and Services (“New Locations”), all New
              Locations will be bound by this Agreement, as of the date of the
              installation of Software at the New Location (the “Initiation
              Date”). All terms and conditions of this Agreement, including but
              not limited to the fees and pricing, shall continue for a period
              equal to the duration of the Term (if a one year Term then same
              one year period shall be added to the Initiation Date) and
              otherwise, and beginning the Initiation Date, unless a new Sign Up
              Page is executed that has different provisions. You agree that you
              may be informed pursuant to the Notice provision below about the
              terms applicable to any New Location. Any non-response, thirty day
              after receipt of such notice, shall be deemed acceptance of same.
            </p>
          </v-col>
          <v-col cols="12">
            <div class="title mb-2">
              2. RESERVATION OF RIGHTS AND OWNERSHIP.
            </div>
            <p>
              The Software is licensed, not sold and Matt Consulting Services Inc
              reserves all rights not expressly granted to you in this
              Agreement. The Software is protected by copyright, trade secret
              and other intellectual property laws. Matt Consulting Services Inc and
              its licensors own the title, copyright, and other worldwide
              intellectual property rights in the Software and all copies of the
              Software. This Agreement does not grant you any rights to
              trademarks or service marks of Matt Consulting Services Inc.
            </p>
          </v-col>
          <v-col cols="12">
            <div class="title mb-2">
              4. CUSTOMER OBLIGATIONS.
            </div>
            <p>
              Customer agrees to purchase the Software and Services more
              particularly described in the Sign Up Page. Customer acknowledges
              and agrees that Customer must:
            </p>
            <p>
              (a) provide for its own access to the internet and pay any service
              fees associated with such access, and
            </p>
            <p>
              (b) provide all equipment necessary for Customer to make such
              connection to the World Wide Web, including a computer and modem.
              Customer shall, at its own expense, provide all necessary
              preparations required to connect to the Software and Services and
              comply with Matt Consulting Services Inc’s installation and maintenance
              specifications for delivery of the Software and the Services.
              Customer shall be responsible for the costs of any relocation of
              its equipment or installation of Software. Additionally, Customer
              shall provide Matt Consulting Services Inc or its agents with
              reasonable access to Customer’s premises to perform any acts
              required under this Agreement. Customer shall be responsible for
              all hardware, software, cabling, services and components not
              provided by Matt Consulting Services Inc. If such items impair
              Customer’s use of the Software and Services, Customer shall remain
              liable for payment to Matt Consulting Services Inc for the Software and
              Services. Upon notice from Matt Consulting Services Inc that any such
              component causes or is likely to cause a hazard, interference or
              obstruction of the Services, Customer shall eliminate such item
              promptly, and Matt Consulting Services Inc may disconnect the Software
              and Services immediately until such elimination occurs. Matt Consulting Services Inc shall not be responsible:
            </p>
            <p>
              (a) for the installation, operation, management or maintenance of
              any hardware, software, cabling or services not provided by
              Matt Consulting Services Inc in connection with the Software and
              Services;
            </p>
            <p>
              (b) if any changes in the Software and Services cause hardware,
              software, configurations, cabling or services not provided by
              Matt Consulting Services Inc to become obsolete or to require
              modification;
            </p>
            <p>
              (c) if any modification or configuration performed by Customer
              impairs the performance of the Software and Services hereunder; or
            </p>
            <p>
              (d) for the performance or availability of third party services or
              facilities provided hereunder. Customer represents that, to the
              best of its knowledge and belief, its use of the Software and
              Services does not directly or indirectly infringe the legal rights
              of a third party. Customer further represents and warrants that
              all information provided by Customer in connection with its
              registration is accurate and reliable.
            </p>
          </v-col>
          <v-col cols="12">
            <div class="title mb-2">
              3. THIRD PARTY SERVICES AND WEBSITES.
            </div>
            <p>
              (a) In connection with the promotion or your use of the Software,
              you may be made aware of or offered services, features, products,
              and promotions provided by third parties, and not by Matt Consulting Services Inc (“Third Party Services”). If you decide to use Third
              Party Services, you are responsible for reviewing and
              understanding the terms and conditions governing any Third Party
              Services. You authorize Matt Consulting Services Inc to use and
              disclose your contact information, including name and address, for
              the purpose of making the Third Party Services you choose
              available to you. You agree that the third party, and not
              Matt Consulting Services Inc, is responsible for the performance of the
              Third Party Services.
            </p>
            <p>
              (b) The Software may contain or reference links to third party
              websites operated by third parties (“Third Party Websites”). These
              links are provided as a convenience only. Such Third Party
              Websites are not under the control of Matt Consulting Services Inc.
              Matt Consulting Services Inc is not responsible for the content of any
              Third Party Website or any link contained in a Third Party
              Website. Matt Consulting Services Inc does not review, approve,
              monitor, endorse, warrant, or make any representations with
              respect to Third Party Websites, and the inclusion of any link in
              the Software or Services is not and does not imply an affiliation,
              sponsorship, endorsement, approval, investigation, verification or
              monitoring by Matt Consulting Services Inc or its Suppliers (defined
              below) of any information contained in any Third Party Website. In
              no event will Matt Consulting Services Inc be responsible for the
              information contained in such Third Party Website or for your use
              of or inability to use such website. Access to any Third Party
              Website is at your own risk, and you acknowledge and understand
              that linked Third Party Websites may contain terms and privacy
              policies that are different from those of Matt Consulting Services Inc.
              Matt Consulting Services Inc is not responsible for such provisions,
              and expressly disclaims any liability for them.
            </p>
          </v-col>
          <v-col cols="12">
            <div class="title mb-2">
              5. CUSTOMER CONDUCT ON THE Matt Consulting Services Inc WEB SITES.
            </div>
            <p>
              While using the various Matt Consulting Services Inc Web sites,
              including but not limited to: smplsale.com, that Matt Consulting Services Inc may create hereafter, Customer may not:
            </p>

            <p>
              (a) Restrict or inhibit any other user from using and enjoying the
              Service;
            </p>
            <p>
              (b) Post or transmit any unlawful, fraudulent, libelous,
              defamatory, obscene, pornographic, profane, threatening, abusive,
              hateful, offensive, or otherwise objectionable information of any
              kind, including without limitation any transmissions constituting
              or encouraging conduct that would constitute a criminal offense,
              give rise to civil liability, or otherwise violate any local,
              state, national or foreign law, including without limitation the
              U.S. export control laws and regulations; or
            </p>
            <p>
              (c) Post or transmit any advertisements, solicitations, chain
              letters, pyramid schemes, investment opportunities or schemes or
              other unsolicited commercial communication (except as otherwise
              expressly permitted by Matt Consulting Services Inc) or engage in
              spamming or flooding; or
            </p>
            <p>
              (d) Post or transmit any information or software which contains a
              virus, trojan horse, worm or other harmful component; or
            </p>
            <p>
              (e) Post, publish, transmit, reproduce, distribute or in any way
              exploit any information, software or other material obtained
              through the Service for commercial purposes (other than as
              expressly permitted by the provider of such information, software
              or other material); or
            </p>
            <p>
              (f) Post, publish, transmit, reproduce, or distribute in any way,
              information, software or other material obtained through the
              Service which is protected by copyright, or other proprietary
              right, or derivative works with respect thereto, without obtaining
              permission of the copyright owner or rightholder; or
            </p>
            <p>
              (g) Upload, post, publish, reproduce, transmit or distribute in
              any way any component of the Service itself or derivative works
              with respect thereto, as the Service is copyrighted as a
              collective work under U.S. copyright laws. Matt Consulting Services Inc
              has no obligation to monitor the Software or Service. However,
              Customer acknowledges and agrees that Matt Consulting Services Inc has
              the right to monitor the Software and Service electronically from
              time to time and to disclose any information as necessary or
              appropriate to satisfy any law, regulation or other governmental
              request, to operate the Software and Services properly, or to
              protect itself or its subscribers. Matt Consulting Services Inc will
              not intentionally monitor or disclose any private electronic-mail
              message unless required by law. Matt Consulting Services Inc reserves
              the right to refuse to post or to remove any information or
              materials, in whole or in part, that, in its sole discretion, are
              unacceptable, undesirable, inappropriate or in violation of this
              Agreement. CSP 1.1
            </p>
          </v-col>
          <v-col cols="12">
            <div class="title mb-2">
              6. GENERAL PRACTICES REGARDING USE, STORAGE AND SERVICE ACCESS.
            </div>
            <p>
              Customer acknowledges that Matt Consulting Services Inc may establish
              from time to time general practices and limits concerning use of
              the Software and Services, in its sole discretion, with or without
              notice, including without limitation, establishing the maximum
              amount of storage space Customer have at any time, as well as
              limiting the number of times (and the maximum duration for which)
              Customer may access the Software and Service in a given period of
              time. Customer agrees that Matt Consulting Services Inc has no
              responsibility or liability for the deletion or failure to store
              any messages and other communications or other Content maintained
              or transmitted by the Software or Services. Customer understands
              that all Content, whether publicly posted or privately
              transmitted, is the sole responsibility of the person from which
              such Content originated. This means that Customer, and not
              Matt Consulting Services Inc, is entirely responsible for all Content
              that Customer uploads or otherwise transmit via the Software and
              Services. Matt Consulting Services Inc does not control the Content
              uploaded or otherwise transmitted by Customer or other customers
              and, as such, does not guarantee the accuracy, integrity or
              quality of such Content. “Content” means information, data, text
              (including but not limited to names of files, databases,
              directories and groups of the same), software, graphics, video,
              messages or other materials. Customer acknowledges and agrees that
              Matt Consulting Services Inc shall have access to Customer’s client
              data and other information which may be used to produce industry
              reports, and for maintenance and support purposes.
            </p>
          </v-col>
          <v-col cols="12">
            <div class="title mb-2">
              7. FEES AND PAYMENT.
            </div>
            <p>
              (a) Customer shall pay the fees and charges set forth in the
              applicable Sign Up Page, which shall include the costs of third
              party services or products, including increases thereto
              (collectively, the “Fees”). Fees shall be invoiced together with
              any applicable sales, use or other taxes at the beginning of each
              billing period (monthly, quarterly, yearly) as selected by
              Customer. Fees and all applicable taxes shall be paid to Matt Consulting Services Inc via credit / debit card. smplsale may accept
              payments via ACH, cash deposited to our bank account, cash at our
              physical location, PayPal, etc at its sole discretion. Payments
              are due net same day from the date of Matt Consulting Services Inc’s
              invoice and are subject to late charges thereafter, calculated at
              the maximum rate permitted by law. Any payment that Matt Consulting Services Inc is not able to process, such as cancelled checks and
              returns for insufficient funds, shall incur a charge of $35.
              Matt Consulting Services Inc may suspend performance or provision of
              the Services, terminate this Agreement or suspend payment of
              credits due Customer for late payment or nonpayment of Fees and
              Customer shall be liable for any reasonable attorney’s or
              collection agency fees incurred by Matt Consulting Services Inc in
              connection therewith. If Customer is delinquent in its payments,
              Matt Consulting Services Inc may, upon written notice to Customer,
              modify the payment terms to require full payment before the
              further provision of any Services or require other assurances to
              secure Customer’s payment obligations hereunder. Any Services
              upgrades requested by Customer may result in additional fees or
              other charges. Except as otherwise stated in a particular Sign Up
              Page for the Services performed thereunder, Matt Consulting Services Inc reserves the right to change the Services it offers to its
              customers generally and the related rates at any time.
            </p>

            <p>
              (b) Customer is responsible for any taxes, duties, fees or
              surcharges that are imposed or authorized by regulatory and
              governmental entities, including but not limited to sales, use,
              gross receipts taxes, surcharges, franchise fees, occupational,
              excise, universal service (state and federal) taxes and
              surcharges, and shall pay to Matt Consulting Services Inc or reimburse
              Matt Consulting Services Inc for amounts paid by Matt Consulting Services Inc relating to Services provided to Customer.
            </p>
            <p>
              (c) Billing shall be provided to Customer by email or fax upon
              customer’s request. If Customer requests that Matt Consulting Services Inc send a print copy of an invoice, Customer shall be responsible
              for payment of an additional fee for such documentation and
              postage.
            </p>
            <p>
              (d) If Customer’s Service is disconnected due to nonpayment or
              late payment by Customer, Customer shall be responsible for all
              costs incurred by Matt Consulting Services Inc resulting from such
              disconnection. Additionally, if Customer requests the restoration
              of the Services, Customer shall be responsible for any fees
              related to such restoration of Services, payable prior to
              restoration.
            </p>
            <p>
              (e) If Customer Fee’s are subsidized by third parties and such
              third parties decline to pay same, Customer shall be responsible
              for those quoted on smplsale.com.
            </p>
            <p>
              (f) If plan prices change, existing accounts will retain the
              grand-fathered price they signed up on. However, any new accounts,
              even for the same owner, will pay the new price.
            </p>
            <p>
              (f) Post, publish, transmit, reproduce, or distribute in any way,
              information, software or other material obtained through the
              Service which is protected by copyright, or other proprietary
              right, or derivative works with respect thereto, without obtaining
              permission of the copyright owner or rightholder; or
            </p>
            <p>
              (g) Upload, post, publish, reproduce, transmit or distribute in
              any way any component of the Service itself or derivative works
              with respect thereto, as the Service is copyrighted as a
              collective work under U.S. copyright laws. Matt Consulting Services Inc
              has no obligation to monitor the Software or Service. However,
              Customer acknowledges and agrees that Matt Consulting Services Inc has
              the right to monitor the Software and Service electronically from
              time to time and to disclose any information as necessary or
              appropriate to satisfy any law, regulation or other governmental
              request, to operate the Software and Services properly, or to
              protect itself or its subscribers. Matt Consulting Services Inc will
              not intentionally monitor or disclose any private electronic-mail
              message unless required by law. Matt Consulting Services Inc reserves
              the right to refuse to post or to remove any information or
              materials, in whole or in part, that, in its sole discretion, are
              unacceptable, undesirable, inappropriate or in violation of this
              Agreement. CSP 1.1
            </p>
          </v-col>
          <v-col cols="12">
            <div class="title mb-2">
              8. DISCLAIMER OF WARRANTIES.
            </div>
            <p>
              THE SOFTWARE, SERVICES AND ANY CONTENT ACCESSIBLE THROUGH THE
              SOFTWARE OR SERVICES ARE PROVIDED “AS-IS,” AND TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW, Matt Consulting Services Inc, ITS
              AFFILIATES, LICENSORS, PARTICIPATING FINANCIAL INSTITUTIONS, THIRD
              PARTY CONTENT OR SERVICE PROVIDERS, DEALERS AND SUPPLIERS
              (COLLECTIVELY “SUPPLIERS”) DISCLAIM ALL GUARANTEES AND WARRANTIES,
              WHETHER EXPRESS OR IMPLIED OR STATUTORY, REGARDING THIS SOFTWARE,
              SERVICES, CONTENT, AND RELATED MATERIALS, INCLUDING ANY WARRANTY
              OF FITNESS FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY, AND
              NONINFRINGEMENT. Matt Consulting Services Inc DOES NOT WARRANT ANY
              HARDWARE OR HARDWARE INTERFACE PURCHASED OR USED IN CONJUNCTION
              WITH THIS SOFTWARE OR SERVICES ARE SECURE, VIRUSES, THREAT OF
              HACKERS, INTERRUPTION OR ERRORS, OR THAT THE SOFTWARE OR SERVICES
              WILL MEET YOUR REQUIREMENTS. MATT CONSULTING SERVICE INC IS
              CONTINUOUSLY STRIVING TO UPDATE THEIR SOFTWARE TAKING INTO ACCOUNT
              THE CUSTOMERS FEEDBACK AND REQUIREMENTS AS WELL AS KEEPING UP WITH
              THE LATEST MARKET TRENDS. FURTHER, Matt Consulting Services Inc DOES
              NOT WARRANT ACCESS TO THE INTERNET OR TO ANY OTHER SERVICE OR
              CONTENT OR DATA THROUGH THE SOFTWARE OR CONTINUED ACCESS TO ANY
              TRIAL VERSION OF THE SOFTWARE OR TO THE DATA ENTERED INTO THE
              TRIAL VERSION OF THE SOFTWARE AFTER THE TRIAL PERIOD OF TIME IS
              OVER. IN THAT EVENT, ANY IMPLIED WARRANTIES ARE LIMITED IN
              DURATION TO THIRTY (30) DAYS FROM THE DATE OF PURCHASE OR DELIVERY
              OF THE SOFTWARE, AS APPLICABLE. THE SOFTWARE AND ANY RELATED
              SERVICES OR CONTENT ARE DESIGNED TO OPERATE AND PROVIDE
              INFORMATION WITH THE UNDERSTANDING THAT Matt Consulting Services Inc
              AND ITS SUPPLIERS ARE NOT ENGAGED IN RENDERING LEGAL, ACCOUNTING
              OR OTHER PROFESSIONAL SERVICE. IF LEGAL ADVICE OR OTHER EXPERT
              ASSISTANCE IS REQUIRED, THE SERVICE OF A COMPETENT PROFESSIONAL
              SHOULD BE SOUGHT. Matt Consulting Services Inc EXPRESSLY DISCLAIMS ANY
              REPRESENTATIONS OR WARRANTIES THAT YOUR USE OF THE SOFTWARE WILL
              SATISFY ANY STATUTORY OR REGULATORY OBLIGATIONS, OR WILL ASSIST
              WITH, GUARANTEE OR OTHERWISE ENSURE COMPLIANCE WITH ANY APPLICABLE
              LAWS OR REGULATIONS. YOU ARE SOLELY RESPONSIBLE FOR ENSURING THAT
              YOUR USE OF THIS SOFTWARE, RELATED SERVICES OR CONTENT IS IN
              ACCORDANCE WITH APPLICABLE LAW. IT IS YOUR RESPONSIBILITY TO KEEP
              ABREAST OF CHANGES IN LAWS, REGULATIONS AND ACCOUNTING PRACTICES
              THAT AFFECT YOU AND YOUR BUSINESS.
            </p>
          </v-col>
          <v-col cols="12">
            <div class="title mb-2">
              10. CONSENT TO CONDUCT BUSINESS ELECTRONICALLY.
            </div>
            <p>
              (a) Consent to Electronic Communications. Matt Consulting Services Inc
              may be required by law to send “Communications” (as defined below)
              to you that may pertain to the Software, the use of information
              you may submit to Matt Consulting Services Inc, and the Third Party
              Services you choose. Additionally, certain of the Third Party
              Services you choose may require Communications with the third
              parties who administer these programs. You agree that Matt Consulting Services Inc, on behalf of itself, and others who administer such
              services (as applicable), may send Communications to you by email
              and/or may make Communications available to you by posting them at
              one or more of our sponsored websites, such as www.smplsale.com. You consent to receive these Communications
              electronically. The term “Communications” means any notice,
              record, agreement, or other type of information that is made
              available to you or received from you in connection with the
              Software and the Third Party Services.
            </p>
            <p>
              (b) Consenting to Do Business Electronically. The decision whether
              to do business electronically is yours, and you should consider
              whether you have the required hardware and software capabilities
              described below. Your consent to do business electronically, and
              our agreement to do so, applies to this Agreement, the Software
              and any applicable Third Party Services.
            </p>
            <p>
              (c) Communication Requirements. In order to access and retain an
              electronic record of Communications, you will need: a computer, a
              monitor, a connection to an Internet service provider, an Internet
              browser software that supports 128-bit encryption, and an e-mail
              address. We do not provide ISP services. You must have your own
              Internet service provider.
            </p>
            <p>
              (d) Changes to Your Email Address. In order to provide you with
              the Communications, you agree to notify us promptly of any change
              in your email address. You can do so by emailing us at
              info@smplsale.com (please include both your old and new email
              addresses).
            </p>
          </v-col>

          <v-col cols="12">
            <div class="title mb-2">
              11. TERMINATION AND END OF TERM.
            </div>
            <p>
              (A) Termination for Cause. Matt Consulting Services Inc may terminate
              this Agreement upon written notice if:
              <ul>
              <li>the Customer materially breaches a non-payment obligation or representation of this Agreement or the applicable Sign Up Page and fails to cure such breach within thirty (30) days following its receipt of notice of such breach;</li>
              <li> the Customer fails to function as a going concern or operate in the ordinary course;</li>
              <li>there is an assignment by the Customer for the benefit its of creditors; or</li>
              <li>there is a voluntary or involuntary bankruptcy filing by or against the Customer. Matt Consulting Services Inc may immediately terminate this Agreement without notice and opportunity to cure and disconnect the Software and services upon a breach of the payment terms in this Agreement. Matt Consulting Services Inc may terminate this Agreement without cause, and for any or no reason, upon thirty (30) days prior written notice to Customer.</li>
              </ul>
            </p>
            <p>
             (B) Termination Fees. There are no termination or cancellation fees, except the Customer shall immediately pay all remaining charges that would be due to Matt Consulting Services Inc through the end of the Term,
            </p>
            <p>
             (C) Effect of Termination. Termination of this Agreement shall not limit either party from pursuing other remedies available to it, including injunctive relief, nor shall such termination relieve Customer of its obligation to pay all fees that have accrued or are otherwise owed by Customer, including Termination Fees. Upon expiration or termination of this Agreement:
             <ul>
               <li>the rights granted to Customer under this Agreement will cease immediately upon the effective date of such termination and be of no further force or effect, and</li>
               <li>Customer shall not have access to the Software or the data therein.</li>
             </ul>
            </p>
            <p>
             (D) End of Term. Unless otherwise provided in the Sign Up Page, the Term of this Agreement shall automatically be renewed for a period similar to that which the customer signed up initially unless Customer gives prior written notice to Matt Consulting Services Inc of its intent not to renew same at least thirty (30) days prior to the end of any Term.
            </p>
          </v-col>
          
          <v-col cols="12">
            <div class="title mb-2">
              12. REFUNDS
            </div>
            <p>
              All software plans are backed by a 30-day money guarantee in the first 30 days after initial sign-up. After the 30-day refund window is passed, monthly and quarterly plans are not refundable. If cancellation is requested, your payment information will be removed from the file and there will be no further charges. You will be able to use smplsale for the time left on your paid plan.
            </p>
          </v-col>
          <v-col cols="12">
            <div class="title mb-2">
              13. HARDWARE
            </div>
            <p>
              All software plans are backed by a 30-day money guarantee in the first 30 days after initial sign-up. After the 30-day refund window is passed, monthly and quarterly plans are not refundable. If cancellation is requested, your payment information will be removed from the file and there will be no further charges. You will be able to use smplsale for the time left on your paid plan.
            </p>
          </v-col>

          <v-col cols="12">
            <div class="title mb-2">
              14. SHIPPING
            </div>
            <p>
              Regular ground shipping is provided at the flat rate of $10 for the 48 contiguous states. Alaska, Hawaii, Puerto Rico and all international orders will be charged actual shipping costs from our shipper of choice.
            </p>
          </v-col>
          <v-col cols="12">
            <div class="title mb-2">
              15. INDEMNITY
            </div>
            <p>Customer shall indemnify and hold Matt Consulting Services Inc harmless from and against all loss, liability, damage and expense, including reasonable attorneys fees, caused by Customer’s officers, employees, agents, vendors, partners or contractors arising from claims or demands:</p>
            <p>(a) for damages to property or for injury or death to persons, including without limitation any disability, death or Worker’s Compensation benefits;</p>
            <p>(b) arising from data transmitted, received or stored on or over Matt Consulting Services Inc’s network by or through Customer;</p>
            <p>(c) for infringement of a third party’s proprietary rights based on any information, materials or access to property provided by Customer; or</p>
            <p>(d) relating to the use by Customer of any Services provided under this Agreement.</p>
          </v-col>
          <v-col cols="12">
            <div class="title mb-2">
              16. MISCELLANEOUS
            </div>
            <p>(a) Equitable Remedies. Customer acknowledges that any breach or threatened breach by Customer of any of the agreements or other or provisions contained in this Agreement will result in irreparable and continuing harm to Matt Consulting Services Inc for which the Matt Consulting Services Inc would not have adequate remedy at law. Therefore Customer acknowledges and agrees that in the event of any such breach or threatened breach, in addition to any other remedy which Matt Consulting Services Inc may have at law or in equity, the Matt Consulting Services Inc shall be entitled to such injunctive relief or other equitable remedies to restrain Customer from violating the provisions of this Agreement and Customer hereby consents to the granting of such injunctive relief or other equitable remedy by any court of competent jurisdiction, without Matt Consulting Services Inc having to prove the inadequacy of the available remedies at law or any actual damages (and without being required to post a bond or other security). Any such remedy sought or obtained shall not be considered either exclusive or a waiver of the rights of Matt Consulting Services Inc to assert a claim for any other remedies it may have at law or in equity.</p>
            <p>(b) Severability and Waiver. If any term or provision of this Agreement is held by a court of competent jurisdiction to be invalid, void, or unenforceable, the remainder of the terms and provisions set forth herein shall remain in full force and effect and shall in no way be affected, impaired or invalidated, and the parties hereto shall use their best efforts to find and employ an alternative means to achieve the same or substantially the same result as that contemplated by such term or provision. The waiver by Matt Consulting Services Inc of any default or breach of this Agreement shall not constitute a waiver of any other or subsequent default or breach.</p>
            <p>(c) Governing Law. This Master Agreement shall be governed by the laws of the State of New York without reference to the principles of conflicts of law. Each party hereby irrevocably submits to the jurisdiction of the courts of the State of New York, sitting in the Bronx County, and the courts of the United States for the Southern District of New York. Each party irrevocably waives, to the fullest extent permitted by law, any objection which it may now or hereafter have to the laying of the venue of any such suit, action or proceeding brought in any such court, any claim that any such suit, action or proceeding brought in such a court has been brought in an inconvenient forum and the right to object, with respect to any such suit, action or proceeding brought in any such court, that such court does not have jurisdiction over such party. In any such suit, action or proceeding, each party waives, to the fullest extent it may effectively do so, personal service of any summons, complaint or other process and agrees that the service thereof may be made by certified or registered mail, addressed to such party at its address as set forth in the preamble hereinabove.</p>
            <p>(d) Assignment. Neither this Agreement nor any rights granted hereunder may be sold, leased, assigned, or otherwise transferred, in whole or in part, by Customer, and any such attempted assignment shall be void and of no effect, without the advance written consent of Matt Consulting Services Inc.</p>
            <p>(e) Force Majeure. Neither Party shall be liable for any delay or failure in performance due to war, acts of terror, riots, embargoes, strikes, accidents, fire, acts of God, supplier or vendor failure, outage or malfunction of local or long distance telecommunications services, utility outage or other occurrence beyond such Party’s direct control (each, a “Force Majeure Event”). The non-performing Party shall notify the other Party of a Force Majeure Event, and if a Force Majeure Event continues for more than sixty (60) days, Matt Consulting Services Inc or Customer may cancel this Agreement with no further liability (except for any amounts due and not paid by Customer) as a result of such Force Majeure Event.</p>
            <p>(g) Notices. All notices required to be sent hereunder shall be in writing and shall be deemed to have been given upon
                <ul>
                  <li>the date sent by confirmed facsimile,</li>
                  <li>on the date it was delivered by courier, (iii) by email if to Matt Consulting Services Inc at info@smplsale.com, if to Customer at the email address provided on the Sales Oder Form, or (iv) if by certified mail return receipt requested, on the date received, to the addresses set forth above and to the attention of the signatories of this Agreement and the relevant Sign Up Page, or to such other address or individual as the parties may specify from time to time by written notice to the other Party. For printed version of this Agreement, please sign below:</li>
                </ul>
            </p>

          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </div>
</template>
<script>
export default {
  data: () => ({
    icons: [
      "fab fa-facebook",
      "fab fa-twitter",
      "fab fa-google-plus",
      "fab fa-linkedin",
      "fab fa-instagram",
    ],
  }),
  methods: {
    openYoutube() {
      // let routeData = this.$router.resolve({
      //   path: `https://www.youtube.com/channel/UCiIE6MOvysf-WoexwOOOfTw?view_as=subscriber`,
      // });
      window.open(
        "https://www.youtube.com/channel/UCiIE6MOvysf-WoexwOOOfTw?view_as=subscriber",
        "_blank"
      );
      // window.open(routeData.href, "_blank");
    },
  },
};
</script>
